<script setup lang="ts">
import { h } from 'vue';
import { ClientApiError } from "@shopware-pwa/types";

const emits = defineEmits<{
  (e: "success"): void;
  (e: "close"): void;
}>();
const { login } = useUser();
const { t } = useI18n();
const { refreshSessionContext } = useSessionContext();
const { mergeWishlistProducts } = useWishlist();
const { pushSuccess, pushError } = useNotificationsEx();

const formData = ref({
  username: "",
  password: "",
  remember: true,
});

const loading = ref(false);

const invokeLogin = async (): Promise<void> => {
  loading.value = true;

  try {
    // TODO: remove this line once the https://github.com/shopware/frontends/issues/112 issue is fixed
    await refreshSessionContext();
    await login(formData.value);
    emits("success");
    pushSuccess(t("account.messages.loggedInSuccess"));
    emits("close");
    mergeWishlistProducts();
  } catch (error) {
    const e = error as ClientApiError;
    pushError(t("account.messages.loggedInError"));
  }

  loading.value = false;
};
</script>

<template>
  <slot name="title">
    <h3 class="text-h3 text-maas-typography-headline-primary">
      Mit <span class="inline-block">Maas Account</span> anmelden
    </h3>
  </slot>
  <slot name="subtitle">
    <p class="text-p mt-2">
      Vereinfachter Bestellvorgang, persönliche Bestellübersicht und exklusive Vorteile für Kontoinhaber.
    </p>
  </slot>

  <form class="mt-4" @submit.prevent="invokeLogin">
    <input v-model="formData.remember" type="hidden" name="remember" data-testid="login-remember-input" />

    <SharedFormLabel label="E-Mail">
      <SharedFormInput
        id="email-address"
        :focus="true"
        v-model="formData.username"
        name="email"
        type="email"
        autocomplete="email"
        placeholder="Ihre E-Mail Adresse"
        data-testid="login-email-input"
        required
      />
    </SharedFormLabel>

    <SharedFormLabel :allow-html="true" label="Passwort">
      <SharedFormInput
        id="password"
        v-model="formData.password"
        name="password"
        type="password"
        autocomplete="current-password"
        placeholder="Ihr Passwort"
        data-testid="login-password-input"
        required
      />
      <span @click="$emit('close')">
        <NuxtLink to="/account/recover" class="inline-block text-maas-typography-text-grey-medium mt-2 text-a text-small">Passwort vergessen?</NuxtLink>
      </span>
    </SharedFormLabel>

    <slot :data="formData" />

    <SharedFormButton type="submit" layout="primary" data-testid="login-submit-button" class="mt-4 w-full" :loading="loading" :disabled="loading">
      Anmelden
    </SharedFormButton>

    <slot name="action">
      <p class="mt-8">
        Neu bei Maas?
        <span @click="$emit('close')">
          <NuxtLink class="text-a" to="/register" data-testid="login-sign-up-link">Jetzt registrieren</NuxtLink>
        </span>
      </p>
    </slot>
  </form>
</template>
